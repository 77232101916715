import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";


export default function HowItWorks({dataMap , PhoneNumber , howWorks}) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach(ref => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };
 
  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
   <div className="howWorks">
    
 
    <div className="main">
      <div className="left-container">
        <div className="img-container">
          <img src={howWorks.Image2} className="img1 img222" alt="" />
          <div className="text">BEST ELDERLY CARE FOR YOUR FAMILY</div>
          <img className="img2" src={howWorks.Image1} alt="" />
        </div>
      </div>
      <div className="right-container">
        <div className="top">
          <h5>WHY CHOOSE US</h5>
          <h4>{howWorks.Heading}</h4>
        </div>
        {/* <p className="paragraph">
          Lorem Ipsum is simply dummy text of the printing and estting industry.
          Lorem Ipsum has been the industry's standard dummy
        </p> */}
        <div className="icons-wrap">
          {dataMap.map((item, index) => (
            <div className="icons" key={index}>
              <div className="image">
                <img src={item.img} alt="" />
              </div>
              <div className="titles">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
      
        </div>
        <p className="btn-group">
            <a href={`tel:${telPhoneNumber}`}>
            CALL NOW:{PhoneNumber.PhoneNo}
            </a>
          </p>
      </div>
    </div>
  
   </div>
  );
}
