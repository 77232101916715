import React from "react";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import WhyChooseUs from "./components/WhyChooseUs";
import Services from "./components/Services";
import HowItWorks from "./components/HowItWorks";
import Contact from "./components/Contact";
import MobileNavbar from "./components/MobileNavbar";
import Footer from "./components/Footer";
import img11 from "./assets/images/photos/10.webp";
import img22 from "./assets/images/photos/11.webp";
import img33 from "./assets/images/photos/3.webp";
import img44 from "./assets/images/photos/14.webp";
import one from "./assets/images/iconss/Comprehensive Database.png";
import two from "./assets/images/iconss/Experts in the Assisted Living and Nursing Home Industry.png";
import three from "./assets/images/iconss/Fastest, Easiest Way to Find the Perfect Nursing Home.png";
import four from "./assets/images/iconss/Personalized Assistance.png";
import img55 from "./assets/images/photos/1.webp";
import img1111 from "./assets/images/photos/22.webp";
import img2222 from "./assets/images/photos/24.webp";
export default function landingPage() {
  const heroSection = {
    SubHeading: "Connecting You with Premier Care Options",
    Heading: " Find the Perfect Assisted Living Home",
    leftImage: img11,
    rightImage: img22,

    PhoneNo: "(877) 403-2065",
  };

  const dataMapChooseUs = [
    {
      // img: nursingIcon,
      // title: "Free Medical CheckUp",
      description:
        "You love your elderly or disabled family member, but you can’t take care of them all the time. You need to find a safe and comfortable place for them to live where they will be taken care of.",
    },
    {
      // img: homeIcon,
      // title: "24/7 Emergency Care",
      description:
        "Finding the right care facility for your elderly or disabled loved one can be daunting. There are many factors to consider, and it’s hard to know where to start. That’s where we come in.",
    },
    {
      // img: savingIcon,
      // title: "Low Cost Services",
      description:
        "Your peace of mind is our priority. Explore our network of premier assisted living facilities and discover the perfect match for your family’s needs. Contact us today to take the first step towards quality care and comfort.",
    },
  ];
  const whyChooseUs = {
    Heading: "Find Assisted Living & Nursing Home Options Near You",
    SmallImage: img44,
    BigImage: img33,
  };
  const dataMap = [
    {
      img: one,
      title: "Comprehensive Database",
      description:
        " Our extensive database includes a wide range of assisted living facilities across the country. With our broad selection, we can help you find the perfect home for your loved one quickly and efficiently.",
    },
    {
      img: two,
      title: "Experts in the Assisted Living and Nursing Home Industry ",
      description:
        "Our team is dedicated to helping you locate the ideal care facility. With years of experience and deep industry knowledge, we provide the expertise and resources you need to make the best decision for your family.",
    },
    {
      img: three,
      title: "Fastest, Easiest Way to Find the Perfect Nursing Home",
      description:
        " Finding the right nursing home or assisted living facility can be challenging and time-consuming. We streamline this process to make it as easy and stress-free as possible, ensuring you find the best fit for your loved one swiftly.",
    },
    {
      img: four,
      title: "Personalized Assistance ",
      description:
        "We understand that every family's situation is unique, and we're committed to providing personalized support tailored to your specific needs.",
    },
  ];
  const howWorks = {
    Heading: "Let Us Take The Burden Off Your Shoulders",
    Image1: img1111,
    Image2: img2222,
  };
  const service = {
    Heading: ` Do You Have A Loved One Who Is No Longer Able To  Live Independently?`,
    Text: "Explore our network of premier assisted living facilities and discover the perfect match for your family’s needs. Let us help you navigate the choices to ensure your loved one receives the best possible support and quality of life.",
  };
  const PhoneNumber = {
    PhoneNo: "(877) 403-2065",
  };
  const ContactText = {
    Image: img55,
    Heading:
      "Make Informed Assisted Living Decisions Rely on Our Expertise for the Perfect Choice",
  };

  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection  heroSection={heroSection} />

      <WhyChooseUs
        whyChooseUs={whyChooseUs}
        dataMapChooseUs={dataMapChooseUs}
        PhoneNumber={PhoneNumber}
      />
      <HowItWorks
        PhoneNumber={PhoneNumber}
        howWorks={howWorks}
        dataMap={dataMap}
      />
      <Services service={service} PhoneNumber={PhoneNumber} />
      <Contact Contact={ContactText} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
