import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
import arrow from '../assets/images/iconss/arrow.png';
export default function WhyChooseUs({HowitWorks , dataMapChooseUs,whyChooseUs, PhoneNumber}) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach(ref => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };
  
  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
   <div className="howWorks">
    
 
    <div className="main why">
    <div className="right-container">
        <div className="top">
          {/* <h5>We Always Care Eldery</h5> */}
          <h4>{whyChooseUs.Heading}
          </h4>
        </div>
        {/* <p className="paragraph">
          Lorem Ipsum is simply dummy text of the printing and estting industry.
          Lorem Ipsum has been the industry's standard dummy
        </p> */}
        <div className="icons-wrap">
          {dataMapChooseUs.map((item, index) => (
            <div className="icons" key={index}>
              {/* <div className="image">
                <img src={dot} alt="" />
              </div> */}
              <div className="titles">
                {/* <h3>{item.title}</h3> */}
                <p>{item.description}</p>
              </div>
            </div>
          ))}
      
        </div>
        <p className="btn-group">
            <a href={`tel:${telPhoneNumber}`}>
            CALL NOW: {PhoneNumber.PhoneNo}
            </a>
          </p>
      </div>
      <div className="left-container">
        <div className="img-container">
          <img src={whyChooseUs.BigImage} className="img1" alt="" />
          {/* <div className="text">We Take Care Helping Seniors Learn New Hobbies</div> */}
          <img className="img2" src={whyChooseUs.SmallImage} alt="" />
          <img className="img3" src={arrow} alt="" />
        </div>
      </div>
     
    </div>
  
   </div>
  );
}
