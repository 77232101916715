import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import useLoadScript from "../Utility/useLoadScript";
import ExternalScriptsLoader from "../Scripts/Home_rd_lps";
import img11 from "../assets/images/photos/24.webp";
import img22 from "../assets/images/photos/25.webp";
import one from "../assets/images/iconss/Comprehensive Database.png";
import two from "../assets/images/iconss/Experts in the Assisted Living and Nursing Home Industry.png";
import three from "../assets/images/iconss/Fastest, Easiest Way to Find the Perfect Nursing Home.png";
import four from "../assets/images/iconss/Personalized Assistance.png";
import img55 from "../assets/images/photos/1.webp";
import HowItWorks from "../components/HowItWorks";
import Contact from "../components/Contact";
import img1111 from "../assets/images/photos/21.webp";
import img2222 from "../assets/images/photos/22.webp";
export default function Bng_lp1() {
  useLoadScript("../Scripts/Home_rd_lps.js");
  const heroSection = {
    SubHeading: "We Help Guide You to the Perfect Assisted Living Facility",
    Heading: "Streamline Your Assisted Living Search",
    leftImage: img11,
    rightImage: img22,

    PhoneNo: "(877) 403-2065",
  };
  const dataMap = [
    {
      img: one,
      title: "Comprehensive Database",
      description:
        " Our extensive database includes a wide range of assisted living facilities across the country. With our broad selection, we can help you find the perfect home for your loved one quickly and efficiently.",
    },
    {
      img: two,
      title: "Experts in the Assisted Living and Nursing Home Industry ",
      description:
        "Our team is dedicated to helping you locate the ideal care facility. With years of experience and deep industry knowledge, we provide the expertise and resources you need to make the best decision for your family.",
    },
    {
      img: three,
      title: "Fastest, Easiest Way to Find the Perfect Nursing Home",
      description:
        " Finding the right nursing home or assisted living facility can be challenging and time-consuming. We streamline this process to make it as easy and stress-free as possible, ensuring you find the best fit for your loved one swiftly.",
    },
    {
      img: four,
      title: "Personalized Assistance ",
      description:
        "We understand that every family's situation is unique, and we're committed to providing personalized support tailored to your specific needs.",
    },
  ];
  const howWorks = {
    Heading: "Let Us Take The Burden Off Your Shoulders",
    Image1: img1111,
    Image2: img2222,
  };
  const PhoneNumber = {
    PhoneNo: "(877) 403-2065",
  };
  const ContactText = {
    Image: img55,
    Heading:
         "Secure Quality Care for Your Family Discover Premier Assisted Living Options",
  };
  return (
    <div>
      <ExternalScriptsLoader />
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection heroSection={heroSection} />
      <div className="mb">
        <HowItWorks
          PhoneNumber={PhoneNumber}
          howWorks={howWorks}
          dataMap={dataMap}
        />
      </div>
      <Contact Contact={ContactText} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
