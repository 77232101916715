import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
export default function Contact({ PhoneNumber, Contact }) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <div className="contact">
      <div className="contact-flex">
        <div className="contact-image">
          <img src={Contact.Image} alt="" />
        </div>
        <div className="contact-heading">
          <h3>{Contact.Heading}</h3>
        </div>
        <div className="contact-btns">
          <p className="btn-group">
            <a href={`tel:${telPhoneNumber}`}>
            CALL NOW:{PhoneNumber.PhoneNo}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
