import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
import img from "../assets/images/icons/dot-blue.png";
export default function HeroSection({ heroSection, refinance }) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(heroSection.PhoneNo)}`;
  return (
    <div>
      <div className="hero_section">
        <div className="hero-flex">
          <div className="hero-content">
            <h1>{heroSection.Heading}</h1>
             <br />
             
            <span>{heroSection.SubHeading}</span>
            {/* <p>Lorem ipsum dolor sit amet consectetur.</p> */}
            <div className="btn-group">
              <a href={`tel:${telPhoneNumber}`}>CALL NOW:{heroSection.PhoneNo} </a>
            </div>
          </div>
          <div className="hero-images">
            <div className="left-img">
              <img src={heroSection.leftImage} alt="" />
            </div>
            <div className="right-img">
              <img src={heroSection.rightImage} alt="" />
            </div>
        
            <div className="absolute"></div>
            <img className="absolute1" src={img} alt="" />
            <img className="absolute2" src={img} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
