import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";

export default function Services({ service , PhoneNumber }) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <div className="services">
      <div className="head-content ">
        <h4>
          {service.Heading}
         
        </h4>
        <p>
          {service.Text}
        </p>
      </div>
    </div>
  );
}
